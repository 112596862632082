[data-page-element="CloseIcon/V1"] {
      color: #2D2D2D;
position: absolute;
top: 10px;
right: 10px;
cursor: pointer;
    }

.elSpinnerCircle {
    display: inline-block;
    transform: translateZ(1px);
  }
  .elSpinnerCircle > div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: #fff;
    animation: elSpinnerCircle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  @keyframes elSpinnerCircle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

.elSpinnerDualRing {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .elSpinnerDualRing:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: elSpinnerDualRing 1.2s linear infinite;
  }
  @keyframes elSpinnerDualRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.elSpinnerBars {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerBars div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: elSpinnerBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .elSpinnerBars div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .elSpinnerBars div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .elSpinnerBars div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes elSpinnerBars {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

.elSpinnerHeart {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
  }
  .elSpinnerHeart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #fff;
    animation: elSpinnerHeart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .elSpinnerHeart div:after,
  .elSpinnerHeart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #fff;
  }
  .elSpinnerHeart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  .elSpinnerHeart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
  @keyframes elSpinnerHeart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }

.elSpinnerRing {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    animation: elSpinnerRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 8px solid #fff;
    border-color: #fff transparent transparent transparent;
  }
  .elSpinnerRing div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .elSpinnerRing div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .elSpinnerRing div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes elSpinnerRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.elSpinnerRoller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerRoller div {
    animation: elSpinnerRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .elSpinnerRoller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .elSpinnerRoller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .elSpinnerRoller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .elSpinnerRoller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .elSpinnerRoller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .elSpinnerRoller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .elSpinnerRoller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .elSpinnerRoller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .elSpinnerRoller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .elSpinnerRoller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .elSpinnerRoller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .elSpinnerRoller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .elSpinnerRoller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .elSpinnerRoller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .elSpinnerRoller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .elSpinnerRoller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .elSpinnerRoller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes elSpinnerRoller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.elSpinnerDots {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerDots div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: elSpinnerDots 1.2s linear infinite;
  }
  .elSpinnerDots div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .elSpinnerDots div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .elSpinnerDots div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .elSpinnerDots div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .elSpinnerDots div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .elSpinnerDots div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .elSpinnerDots div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .elSpinnerDots div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .elSpinnerDots div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .elSpinnerDots div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .elSpinnerDots div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .elSpinnerDots div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes elSpinnerDots {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

.elSpinnerEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerEllipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .elSpinnerEllipsis div:nth-child(1) {
    left: 8px;
    animation: elSpinnerEllipsis1 0.6s infinite;
  }
  .elSpinnerEllipsis div:nth-child(2) {
    left: 8px;
    animation: elSpinnerEllipsis2 0.6s infinite;
  }
  .elSpinnerEllipsis div:nth-child(3) {
    left: 32px;
    animation: elSpinnerEllipsis2 0.6s infinite;
  }
  .elSpinnerEllipsis div:nth-child(4) {
    left: 56px;
    animation: elSpinnerEllipsis3 0.6s infinite;
  }
  @keyframes elSpinnerEllipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes elSpinnerEllipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes elSpinnerEllipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

.elSpinnerGrid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerGrid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: elSpinnerGrid 1.2s linear infinite;
  }
  .elSpinnerGrid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .elSpinnerGrid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .elSpinnerGrid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .elSpinnerGrid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .elSpinnerGrid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .elSpinnerGrid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .elSpinnerGrid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .elSpinnerGrid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .elSpinnerGrid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes elSpinnerGrid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

.elSpinnerHourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerHourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: elSpinnerHourglass 1.2s infinite;
  }
  @keyframes elSpinnerHourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

.elSpinnerRipple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerRipple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: elSpinnerRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .elSpinnerRipple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes elSpinnerRipple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

.elSpinnerLongBars {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .elSpinnerLongBars div {
    transform-origin: 40px 40px;
    animation: elSpinnerLongBars 1.2s linear infinite;
  }
  .elSpinnerLongBars div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .elSpinnerLongBars div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .elSpinnerLongBars div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .elSpinnerLongBars div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .elSpinnerLongBars div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .elSpinnerLongBars div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .elSpinnerLongBars div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .elSpinnerLongBars div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .elSpinnerLongBars div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .elSpinnerLongBars div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .elSpinnerLongBars div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .elSpinnerLongBars div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .elSpinnerLongBars div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes elSpinnerLongBars {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

      
      
    
.elSpinnerWrapper[data-loader="true"] {
    display: block;
  }

  .elSpinnerWrapper {
    --spinner-rotating-color: #ffffff;
    --spinner-still-color: 255, 255, 255;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #777777;
    opacity: 0.4;
  }

  .elSpinner {
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    animation: load5 1.1s infinite ease;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto !important;
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em var(--spinner-rotating-color), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.5), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.7), 1.8em -1.8em 0 0em var(--spinner-rotating-color), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.5), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.7), 2.5em 0em 0 0em var(--spinner-rotating-color), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.5), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.7), 1.75em 1.75em 0 0em var(--spinner-rotating-color), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.5), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.7), 0em 2.5em 0 0em var(--spinner-rotating-color), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.5), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.7), -1.8em 1.8em 0 0em var(--spinner-rotating-color), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.5), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.7), -2.6em 0em 0 0em var(--spinner-rotating-color), -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2), 1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2), 2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2), 1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2), 0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2), -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.5), -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.7), -1.8em -1.8em 0 0em var(--spinner-rotating-color);
    }
  }

      
      
    
.hide {
    display: none !important;
  }
[data-page-element="ContentNode"] {
      width: 100%;
min-height: 100%;
    }
      [data-page-element="ContentNode"]:before {
      content: "";
display: table;
    }
      
    
select {
      -webkit-appearance: none;
      -moz-appearance: none;
           appearance: none;
    }

    .elSelectWrapper {
      position: relative;
    }

    .elSelectWrapper .elSelectArrow {
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 0px;
      bottom: 0px;
      margin: auto 0px;
      color: inherit;
      font-size: inherit;
      display: flex;
      align-items: center;
      pointer-events: none;
      z-index: 1;
    }

    @keyframes disable-pointer-events {
      0%, 99% { pointer-events: none; }
    }
[data-page-element="SectionContainer/V1"] {
      padding-top: 20px;
padding-bottom: 20px;
width: 100%;
padding-left: 15px;
padding-right: 15px;
    }
      
      
    
[data-page-element="ColContainer/V1"] {
      padding-left: 0px;
padding-right: 0px;
    }
      [data-page-element="ColContainer/V1"] > .col-inner {
      padding-bottom: 0px;
padding-top: 0px;
margin-left: 16px;
margin-right: 16px;
    }
[data-page-element="ColContainer/V1"] > .col-inner:before {
      content: "";
display: table;
    }
[data-page-element="ColContainer/V1"] > .col-inner:after {
      content: "";
display: table;
    }
      
    
[data-page-element="FlexContainer/V1"] {
      display: flex;
justify-content: center;
gap: 1.5em;
padding-top: 20px;
padding-bottom: 20px;
padding-left: 15px;
padding-right: 15px;
    }
      [data-page-element="FlexContainer/V1"].elFlexNoWrap {
      flex-wrap: nowrap;
    }
[data-page-element="FlexContainer/V1"].elFlexWrap {
      flex-wrap: wrap;
    }
[data-page-element="FlexContainer/V1"].elFlexWrapReverse {
      flex-wrap: wrap-reverse;
    }
[data-page-element="FlexContainer/V1"] div {
      margin: 0px;
    }
      @media (max-width: 770px) {
        
      [data-page-element="FlexContainer/V1"].elFlexNoWrapMobile {
      flex-wrap: nowrap;
    }
[data-page-element="FlexContainer/V1"].elFlexWrapMobile {
      flex-wrap: wrap;
    }
[data-page-element="FlexContainer/V1"].elFlexWrapReverseMobile {
      flex-wrap: wrap-reverse;
    }
      
    
      }
    
[data-page-element="RowContainer/V1"] {
      padding-top: 20px;
padding-bottom: 20px;
max-width: 100%;
display: flex;
padding-left: 15px;
padding-right: 15px;
    }
      [data-page-element="RowContainer/V1"] .col-inner {
      height: 100%;
    }
      
    
[data-page-element="CollectionContainer/V1"] {
      grid-template-columns: repeat(3, minmax(0, 1fr));
display: grid;
max-width: 100%;
width: 100%;
    }
      [data-page-element="CollectionContainer/V1"] .innerCollectionContent > * {
      height: 100%;
    }
      @media (max-width: 770px) {
        [data-page-element="CollectionContainer/V1"] {
      grid-template-columns: 1fr;
    }
      }
    
[data-page-element="Modal/V1"] {
      position: fixed;
overflow-y: auto;
overflow-x: hidden;
left: 0px;
top: 0px;
height: 100%;
width: 100%;
cursor: pointer;
display: none;
z-index: 100000;
background: rgba(0, 0, 0, 0.4);
    }
      [data-page-element="Modal/V1"] .elModalInnerContainer {
      z-index: 100001;
cursor: auto;
position: relative;
    }
      
    

      [data-page-element="ModalSidebar/V1"] {
      width: 100%;
height: 100%;
display: flex;
justify-content: end;
align-items: flex-end;
position: fixed;
top: 0px;
left: 0px;
z-index: 1000;
font-family: var(--text-font-family);
margin-left: 0px;
margin-right: 0px;
background: var(--backdrop-color);
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebar {
      display: flex;
width: 550px;
height: 100%;
flex-direction: column;
transform: translateX(100%);
transition: transform 0.25s ease;
margin-left: 0px;
margin-right: 0px;
background: var(--container-color);
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader {
      display: flex;
align-items: center;
padding-left: 24px;
padding-right: 32px;
justify-content: space-between;
height: -moz-fit-content;
height: fit-content;
width: 100%;
margin-left: 0px;
margin-right: 0px;
padding-top: 25px;
padding-bottom: 25px;
border-style: solid;
border-color: var(--divider-color);
border-width: 0px;
border-bottom-width: var(--divider-height);
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader span {
      font-size: 16px;
font-weight: 700;
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader i {
      font-size: 16px;
color: black;
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader a {
      text-decoration: none;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarBody {
      display: flex;
align-items: center;
flex-direction: column;
width: 100%;
overflow: hidden;
margin-left: 0px;
margin-right: 0px;
padding-top: 36px;
padding-bottom: 36px;
    }
[data-page-element="ModalSidebar/V1"] .removeSlideAnimation {
      transform: none !important;
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarSlideAnimation {
      transform: translateX(0%);
    }
      @media (max-width: 768px) {
        
      [data-page-element="ModalSidebar/V1"] .elModalSidebar {
      width: 100%;
height: 85%;
border-top-left-radius: 35px;
border-top-right-radius: 35px;
transform: translateY(100%);
    }
[data-page-element="ModalSidebar/V1"] .elModalSidebarSlideAnimation {
      transform: translateY(0%);
    }
      
    
      }
    

      [data-page-element="ModalContainer/V1"] .modal-wrapper {
      z-index: 60;
opacity: 0;
top: -800px;
    }
[data-page-element="ModalContainer/V1"] .containerModal {
      display: block;
position: relative;
padding-top: 41px;
padding-bottom: 24px;
margin-top: 60px;
margin-bottom: 60px;
width: 100%;
z-index: 222;
overflow: visible !important;
padding-left: 20px;
padding-right: 20px;
background: #fff;
    }
[data-page-element="ModalContainer/V1"] .closeLPModal {
      display: flex;
z-index: 9999;
position: absolute;
right: -20px;
top: -20px;
cursor: pointer;
width: 35px;
height: 35px;
border-radius: 50%;
color: #fff;
align-items: center;
justify-content: center;
background: #031a01;
    }
[data-page-element="ModalContainer/V1"] .elModalInnerContainer {
      max-width: 100%;
width: 50%;
    }
      @media (max-width: 770px) {
        
      [data-page-element="ModalContainer/V1"] .elModalInnerContainer {
      max-width: 100%;
width: 80%;
    }
      
    
      }
    

      
      
    

      [data-page-element="Headline/V1"] .elHeadline {
      text-align: center;
font-size: 32px;
    }
[data-page-element="Headline/V1"].truncateByLine .elHeadline {
      display: -webkit-box;
overflow: hidden;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
    }
      
    

      [data-page-element="SubHeadline/V1"] .elSubheadline {
      text-align: center;
font-size: 22px;
    }
      
    

      [data-page-element="Paragraph/V1"] .elParagraph {
      text-align: center;
    }
[data-page-element="Paragraph/V1"].truncateByLine span {
      display: -webkit-box;
overflow: hidden;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3;
    }
      
    

      [data-page-element="BulletList/V1"] li:not(:first-child) {
      margin-top: 10px;
    }

      [data-page-element="BulletList/V1"] .elBulletList li {
      display: flex;
    }
      
    
[data-page-element="BulletList/V1"] .elBulletList .fa_icon {
      margin-right: 10px;
line-height: inherit;
margin-left: 0px;
flex-shrink: 0;
    }
[data-page-element="BulletList/V1"] .elBulletListTextWrapper {
      min-width: 5px;
    }
      
    
.elBulletList[data-list-type='ordered'] ul {
    counter-reset: li;
  }
  .elBulletList[data-list-type='ordered'] li {
    counter-increment: li;
  }
  .elBulletList[data-list-type='ordered'] li i {
    font-family: inherit;
  }
  .elBulletList[data-list-type='ordered'] li i.fa::before,
  .elBulletList[data-list-type='ordered'] li i.far::before,
  .elBulletList[data-list-type='ordered'] li i.fas::before,
  .elBulletList[data-list-type='ordered'] li i.fab::before,
  .elBulletList[data-list-type='ordered'] li i.fad::before {
    content: counter(li) '.';
    display: inline-block;
    font-family: inherit;
  }
[data-page-element="Image/V1"] {
      text-align: center;
margin-top: 15px;
    }
      [data-page-element="Image/V1"] .elImage {
      max-width: 100%;
vertical-align: bottom;
width: 100%;
height: auto;
aspect-ratio: auto;
box-sizing: border-box;
    }
      
    
[data-page-element="Image/V2"] {
      text-align: center;
margin-top: 15px;
    }
      [data-page-element="Image/V2"] .elImage {
      max-width: 100%;
vertical-align: bottom;
width: 100%;
height: auto;
aspect-ratio: auto;
box-sizing: border-box;
    }
      
    
.image-placeholder {
    height: 200px;
    background-image: url("/editor/editor-placeholder-image.svg");
    background-size: 100% 100%;
    padding: 15px;
    text-align: center;
    cursor: help;
  }
  .image-placeholder-header {
    color: grey;
  }
[data-page-element="Button/V1"] {
      margin-top: 15px;
text-align: center;
    }
      [data-page-element="Button/V1"] .fa_prepended.fa_prepended,
[data-page-element="Button/V1"] .fa_apended.fa_apended {
      color: inherit;
    }
[data-page-element="Button/V1"] .elButton {
      display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: auto;
font-weight: 600;
transition: background .2s ease;
padding-left: 25px;
padding-right: 25px;
padding-top: 10px;
padding-bottom: 10px;
background: rgb(1, 116, 199);
    }
[data-page-element="Button/V1"] .elButton .elButtonText {
      font-size: 1.25rem;
line-height: 100%;
color: rgb(255, 255, 255);
transition: color .2s ease;
    }
[data-page-element="Button/V1"] .elButton .elButtonSub {
      font-size: 0.875rem;
line-height: 100%;
opacity: 0.8;
color: rgb(255, 255, 255);
transition: color .2s ease;
    }
[data-page-element="Button/V1"] .elButton:hover,
[data-page-element="Button/V1"] 
.elButton.elButtonHovered {
      background: #018ff5;
    }
[data-page-element="Button/V1"] .elButton:hover .elButtonText,
[data-page-element="Button/V1"] 
.elButton.elButtonHovered .elButtonText {
      color: rgb(255, 255, 255);
    }
[data-page-element="Button/V1"] .elButton:hover .elButtonSub,
[data-page-element="Button/V1"] 
.elButton.elButtonHovered .elButtonSub {
      color: rgb(255, 255, 255);
    }
[data-page-element="Button/V1"] .elButton:active,
[data-page-element="Button/V1"] 
.elButton.elButtonActive {
      background: #0163a9;
    }
[data-page-element="Button/V1"] .elButton:active .elButtonText,
[data-page-element="Button/V1"] 
.elButton.elButtonActive .elButtonText {
      color: rgb(255, 255, 255);
    }
[data-page-element="Button/V1"] .elButton:active .elButtonSub,
[data-page-element="Button/V1"] 
.elButton.elButtonActive .elButtonSub {
      color: rgb(255, 255, 255);
    }
[data-page-element="Button/V1"] .elButtonSpinner.elButtonSpinner.elButtonSpinner.elButtonSpinner {
      display: none;
margin-right: 10px;
font-family: 'Font Awesome 5 Free';
letter-spacing: 0;
line-height: 0;
text-decoration: none;
    }
      @media (max-width: 770px) {
        
      [data-page-element="Button/V1"] .elButton {
      max-width: 100%;
    }
      
    
      }
    
.elButton {
    position: relative;
  }

  .elButton[data-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
  }

  .elButton[href=""] {
    cursor: default;
    pointer-events: none;
  }

  .elButton:hover {
    text-decoration: none;
  }

  .elButtonSub {
    display: block;
  }

  .elButtonSub:empty {
    display: none;
  }
[data-page-element="SelectBox/V1"] {
      margin-top: 15px;
    }
      [data-page-element="SelectBox/V1"] .elSelectLabel {
      position: relative;
font-size: 14px;
color: rgb(21, 21, 23);
    }
[data-page-element="SelectBox/V1"] .elSelect {
      font-family: sans-serif;
font-size: 14px;
cursor: pointer;
width: 100%;
text-align: left;
background-position: 97%;;
box-shadow: inset 0 1px 2px rgba(130, 137, 150, 0.23), 0 1px 0 rgba(255, 255, 255, 0.95);
border-radius: 5px;
padding-top: 12px;
padding-bottom: 12px;
-webkit-appearance: none;
-moz-appearance: none;
     appearance: none;
padding-left: 12px;
padding-right: 12px;
background: #ffffff;
border-style: solid;
border-color: rgba(0, 0, 0, 0.2);
border-width: 1px;
    }
[data-page-element="SelectBox/V1"] .elSelect:focus {
      border-color: #42b0e2;
    }
      
    
[data-page-element="SelectBox/V2"] {
      margin-top: 15px;
background-position: 97%;;
font-family: inherit;
position: relative;
padding: 0 !important;
--label-padding-on-focus: 4px;
border-style: none;
    }
      [data-page-element="SelectBox/V2"] .elInputFocused [data-input-status-type],
[data-page-element="SelectBox/V2"] .hasValue [data-input-status-type] {
      position: relative !important;
    }
[data-page-element="SelectBox/V2"] [data-input-status-type] {
      position: initial;
border-style: none;
padding-top: 0px !important;
padding-bottom: 0px !important;
margin-left: 5px;
top: -10px;
    }
[data-page-element="SelectBox/V2"] .elLabel {
      position: absolute;
top: 0px;
bottom: 0px;
left: 0px;
right: 0px;
pointer-events: none;
border-radius: inherit;
display: flex;
border-width: inherit;
    }
[data-page-element="SelectBox/V2"] .inputHolder {
      border-radius: inherit;
border-color: transparent !important;
    }
[data-page-element="SelectBox/V2"] label {
      top: 50%;
position: relative;
transform: translateY(-50%);
display: block;
line-height: 18px;
transition: all .2s ease;
border-bottom-color: transparent !important;
    }
[data-page-element="SelectBox/V2"].elInputFocused .labelText,
[data-page-element="SelectBox/V2"].hasValue .labelText {
      font-size: 12px;
    }
[data-page-element="SelectBox/V2"].hasValue .elSelect {
      opacity: 1;
    }
[data-page-element="SelectBox/V2"] .elSelect {
      width: 100%;
font-size: 16px;
border-style: none;
background: transparent;
outline: 0;
opacity: 0;
    }
[data-page-element="SelectBox/V2"] .elLabel,
[data-page-element="SelectBox/V2"] .elSelect {
      text-align: left;
    }
[data-page-element="SelectBox/V2"].labelInside .borderHolder {
      display: none;
    }
[data-page-element="SelectBox/V2"].labelInside .labelText {
      display: block;
width: 100%;
    }
[data-page-element="SelectBox/V2"].labelInside.elInputFocused label,
[data-page-element="SelectBox/V2"].labelInside.hasValue label {
      top: 0px;
    }
[data-page-element="SelectBox/V2"].labelInside.elInputFocused .elSelect,
[data-page-element="SelectBox/V2"].labelInside.hasValue .elSelect {
      transform: translateY(15%);
    }
[data-page-element="SelectBox/V2"].labelInside.elInputFocused .labelText {
      font-size: 10px;
    }
[data-page-element="SelectBox/V2"].labelInside.hasValue .labelText {
      font-size: 10px;
    }
[data-page-element="SelectBox/V2"].labelOnBorder .labelText {
      flex: 0 0 auto;
width: auto;
color: rgb(21, 21, 23);
padding-left: var(--label-padding-on-focus) !important;
padding-right: var(--label-padding-on-focus) !important;
border-right: none !important;
border-left: none !important;
flex-grow: 0 !important;
padding-top: 0 !important;
padding-bottom: 0 !important;
border-radius: 0 !important;
font-size: 14px;
    }
[data-page-element="SelectBox/V2"].labelOnBorder.elInputFocused .labelText {
      font-size: 12px;
    }
[data-page-element="SelectBox/V2"].labelOnBorder.hasValue .labelText {
      font-size: 12px;
    }
[data-page-element="SelectBox/V2"].labelOnBorder .borderHolder:last-child {
      border-left: none !important;
flex-grow: 1;
border-top-left-radius: 0 !important;
border-bottom-left-radius: 0 !important;
padding-left: 0 !important;
padding-top: 0 !important;
padding-bottom: 0 !important;
    }
[data-page-element="SelectBox/V2"].labelOnBorder .borderHolder:first-child {
      border-right: none !important;
border-top-right-radius: 0 !important;
border-bottom-right-radius: 0 !important;
    }
[data-page-element="SelectBox/V2"].labelOnBorder .elLabel .borderHolder:first-child {
      padding-right: 0 !important;
    }
[data-page-element="SelectBox/V2"].labelOnBorder.elInputFocused .labelText,
[data-page-element="SelectBox/V2"].labelOnBorder.hasValue .labelText {
      border-top-color: transparent !important;
    }
[data-page-element="SelectBox/V2"].labelOnBorder.elInputFocused label,
[data-page-element="SelectBox/V2"].labelOnBorder.hasValue label {
      top: 0px;
transform: translateY(-50%);
border-bottom: inherit;
    }
      
    
[data-page-element="Audio/V1"] {
      margin-top: 15px;
width: 100%;
    }
      
      
    
.elAudio {
    position: relative;
    overflow: hidden; /* Avoid flash while videos are resized */
  }

  .elAudio audio {
    position: relative;
    top: 0;
    left: 0;
    width: 100% !important;
    box-sizing: border-box;
    z-index: 3;
  }
[data-page-element="Input/V1"] {
      margin: 0px auto;
margin-top: 15px;
font-family: inherit;
position: relative;
padding: 0 !important;
--label-padding-on-focus: 4px;
border: 0 !important;
    }
      [data-page-element="Input/V1"] [data-input-status-type] {
      position: initial !important;
border: none !important;
padding-top: 0px !important;
padding-bottom: 0px !important;
margin-left: 2px;
top: -10px;
    }
[data-page-element="Input/V1"] .elLabel {
      position: absolute;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
pointer-events: none;
border-radius: inherit;
display: flex;
border-width: inherit;
    }
[data-page-element="Input/V1"] label {
      top: 50%;
position: relative;
transform: translateY(-50%);
display: block;
transition: all .2s ease;
border-bottom-color: transparent !important;
    }
[data-page-element="Input/V1"] .borderHolder {
      margin: 0 !important;
box-shadow: none !important;
background: #ffffff;
    }
[data-page-element="Input/V1"] .inputHolder {
      background: transparent;
border-radius: inherit;
border-color: transparent !important;
    }
[data-page-element="Input/V1"] .elInput {
      width: 100%;
font-size: 12px;
background: transparent;
border: 0;
z-index: 1;
position: relative;
    }
[data-page-element="Input/V1"] .elInput::-moz-placeholder {
      font-size: 12px;
color: rgb(96, 96, 107, 80%);
opacity: 0;
    }
[data-page-element="Input/V1"] .elInput::placeholder {
      font-size: 12px;
color: rgb(96, 96, 107, 80%);
opacity: 0;
    }
[data-page-element="Input/V1"].elInputFocused .elInput::-moz-placeholder, [data-page-element="Input/V1"].hasValue .elInput::-moz-placeholder {
      opacity: 1;
    }
[data-page-element="Input/V1"].elInputFocused .elInput::placeholder,
[data-page-element="Input/V1"].hasValue .elInput::placeholder {
      opacity: 1;
    }
[data-page-element="Input/V1"] .elLabel,
[data-page-element="Input/V1"] .elInput {
      text-align: left;
    }
[data-page-element="Input/V1"].labelInside .borderHolder {
      display: none;
    }
[data-page-element="Input/V1"].labelInside .labelText {
      display: block;
width: 100%;
    }
[data-page-element="Input/V1"].labelInside.elInputFocused label,
[data-page-element="Input/V1"].labelInside.hasValue label {
      top: 0px;
    }
[data-page-element="Input/V1"].labelInside.elInputFocused .elInput,
[data-page-element="Input/V1"].labelInside.hasValue .elInput {
      transform: translateY(15%);
    }
[data-page-element="Input/V1"].labelInside.elInputFocused .labelText {
      font-size: 10px;
    }
[data-page-element="Input/V1"].labelInside.hasValue .labelText {
      font-size: 10px;
    }
[data-page-element="Input/V1"].labelOnBorder .labelText {
      flex: 0 0 auto;
width: auto;
color: rgb(21, 21, 23);
padding-left: var(--label-padding-on-focus) !important;
padding-right: var(--label-padding-on-focus) !important;
border-right: none !important;
border-left: none !important;
flex-grow: 0 !important;
padding-top: 0 !important;
padding-bottom: 0 !important;
border-radius: 0 !important;
font-size: 14px;
    }
[data-page-element="Input/V1"].labelOnBorder.elInputFocused .labelText {
      font-size: 12px;
    }
[data-page-element="Input/V1"].labelOnBorder.hasValue .labelText {
      font-size: 12px;
    }
[data-page-element="Input/V1"].labelOnBorder .borderHolder:last-child {
      border-left: none !important;
flex-grow: 1;
border-top-left-radius: 0 !important;
border-bottom-left-radius: 0 !important;
padding-left: 0 !important;
padding-top: 0 !important;
padding-bottom: 0 !important;
    }
[data-page-element="Input/V1"].labelOnBorder .borderHolder:first-child {
      border-right: none !important;
border-top-right-radius: 0 !important;
border-bottom-right-radius: 0 !important;
    }
[data-page-element="Input/V1"].labelOnBorder .elLabel .borderHolder:first-child {
      padding-right: 0 !important;
    }
[data-page-element="Input/V1"].labelOnBorder.elInputFocused .labelText,
[data-page-element="Input/V1"].labelOnBorder.hasValue .labelText {
      border-top-color: transparent !important;
    }
[data-page-element="Input/V1"].labelOnBorder.elInputFocused label,
[data-page-element="Input/V1"].labelOnBorder.hasValue label {
      top: 0px;
transform: translateY(-50%);
border-bottom: inherit;
    }
[data-page-element="Input/V1"] .input-icon {
      width: 16px;
height: 16px;
position: absolute;
border: 1px;
border-radius: 50%;
border-style: solid;
border-width: 2px;
right: 10px;
top: 50%;
transform: translate(0px, -50%);
display: none;
align-items: center;
justify-content: center;
    }
      [data-page-element="Input/V1"] .input-icon i {
      font-size: 10px;
    }
      
    
[data-page-element="Input/V1"] .iti--allow-dropdown .iti__selected-flag:focus {
      background-color: rgba(0, 0, 0, 0.05);
    }
[data-page-element="Input/V1"] .iti__flag-container {
      z-index: 10;
    }
[data-page-element="Input/V1"] .iti {
      width: 100%;
    }
[data-page-element="Input/V1"].labelOnBorder .iti ~ .elLabel .borderHolder:first-child {
      padding-left: 50px !important;
    }
[data-page-element="Input/V1"].labelInside .iti ~ .elLabel .labelText {
      padding-left: 50px;
    }
[data-page-element="Input/V1"] [data-intl-tel-input-id] {
      padding-left: 50px !important;
    }
      
    

      [data-page-element="Checkbox/V1"][data-prevent-click-on-label="true"] .elCheckboxLabel {
      cursor: initial;
    }
[data-page-element="Checkbox/V1"] .elCheckboxLabel {
      position: relative;
cursor: pointer;
display: flex;
gap: 0.5em;
align-items: center;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
    }
[data-page-element="Checkbox/V1"].elCheckboxWrapper .elCheckboxInput.elCheckboxInput.inputHolder {
      position: absolute;
opacity: 0;
cursor: pointer;
height: 0px;
width: 0px;
pointer-events: none;
    }
[data-page-element="Checkbox/V1"] .elCheckbox {
      display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
margin: 0px;
background-color: #fff;
font-size: 12px;
width: 2em;
min-width: 2em;
height: 2em;
min-height: 2em;
border-style: solid;
border-color: #ccc;
border-width: 1px;
    }
[data-page-element="Checkbox/V1"] .elCheckboxLabel .elCheckboxInput:checked ~ .elCheckbox {
      background-color: #2196F3;
border-radius: 0px;
border-style: solid;
border-color: #eee;
border-width: 1px;
    }
[data-page-element="Checkbox/V1"] .elCheckboxIcon {
      display: none;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
    }
[data-page-element="Checkbox/V1"] .elCheckboxIconCheck {
      font-size: inherit;
color: #fff;
line-height: 100%;
    }
[data-page-element="Checkbox/V1"] .elCheckboxIconSquare {
      font-size: inherit;
width: 1em;
height: 1em;
background-color: #fff;
    }
[data-page-element="Checkbox/V1"] .elCheckboxText {
      font-size: 14px;
color: #60606b;
width: 100%;
    }
[data-page-element="Checkbox/V1"] .elCheckboxInput:checked ~ .elCheckbox .elCheckboxIcon {
      display: flex !important;
    }
      
    
.hide {
    display: none !important;
  }
[data-page-element="Radio/V1"] {
      margin: 0px;
    }
      [data-page-element="Radio/V1"] .elRadioLabel {
      position: relative;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
    }
[data-page-element="Radio/V1"] .elRadioInput {
      position: absolute;
opacity: 0;
cursor: pointer;
height: 0px;
margin: 0px;
width: 0px;
    }
[data-page-element="Radio/V1"] .elRadio {
      display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
height: 2em;
min-height: 2em;
width: 2em;
min-width: 2em;
font-size: 14px;
border-radius: 50%;
background-color: #fff;
border-style: solid;
border-color: #939393;
border-width: 1px;
    }
[data-page-element="Radio/V1"] .elRadioLabel .elRadioInput:checked ~ .elRadio {
      background-color: #fff;
border-radius: 50%;
border-style: solid;
border-color: #2196F3;
border-width: 1px;
    }
[data-page-element="Radio/V1"] .elRadioIcon {
      width: 1em;
height: 1em;
font-size: inherit;
border-radius: 50%;
vertical-align: middle;
background-color: #2196F3;
display: none;
margin: 0px;
    }
[data-page-element="Radio/V1"] .elRadioInput:checked ~ .elRadio .elRadioIcon {
      display: block !important;
    }
[data-page-element="Radio/V1"] .elRadioImage {
      max-height: 100px;
    }
      
    
[data-page-element="SuperSelectBox/V1"] {
      background-color: #fff;
background-position: 97%;;
font-family: inherit;
padding: 12px 18px;
    }
      [data-page-element="SuperSelectBox/V1"].elInputFocused {
      border-color: #42b0e2;
    }
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabel {
      display: flex;
flex-direction: row;
    }
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabelText {
      min-width: -moz-fit-content;
      min-width: fit-content;
    }
[data-page-element="SuperSelectBox/V1"] .elSuperSelectIcon,
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabelText,
[data-page-element="SuperSelectBox/V1"] .elSuperSelect {
      text-align: left;
font-size: inherit;
font-family: inherit;
letter-spacing: inherit;
color: inherit;
line-height: inherit;
font-weight: inherit;
text-transform: inherit;
    }
[data-page-element="SuperSelectBox/V1"] .elSuperSelectIcon,
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabelText {
      margin-left: 10px;
margin-right: 10px;
    }
[data-page-element="SuperSelectBox/V1"] .elSuperSelect {
      width: 100%;
flex: auto;
text-align: right;
font-size: inherit;
font-family: inherit;
letter-spacing: inherit;
color: inherit;
line-height: inherit;
font-weight: inherit;
text-transform: inherit;
padding-right: 20px;
border-style: none;
background: transparent;
outline: 0;
    }
[data-page-element="SuperSelectBox/V1"] .elSuperSelect:focus {
      border-color: transparent;
    }
      
    

      [data-page-element="Icon/V1"] .iconElement {
      text-align: center;
cursor: pointer;
display: block;
text-decoration: none;
color: inherit;
    }
[data-page-element="Icon/V1"] .iconElement[href=""] {
      cursor: default;
    }
[data-page-element="Icon/V1"] .fa_icon {
      font-size: 55px;
    }
      
    
[data-page-element="Divider/V1"] {
      padding-top: 10px;
padding-bottom: 10px;
margin-top: 0px;
margin-bottom: 0px;
flex-basis: 100%;
align-self: stretch;
    }
      [data-page-element="Divider/V1"] .elDivider {
      width: 100%;
min-height: 1px;
margin: 0px;
border-style: solid;
border-top: 1px solid;
border-bottom-width: 0px;
border-left-width: 0px;
border-right-width: 0px;
    }
      
    
[data-page-element="Link/V1"] {
      text-align: center;
    }
      
      
    
[data-page-element="CustomHtmlJs/V1"] {
      margin-top: 15px;
    }
      
      
    

      [data-page-element="Breadcrumbs/V1"] .elBreadcrumbs {
      display: flex;
max-width: 100%;
    }
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbItem {
      display: flex;
margin: 0px;
color: #4b5563;
    }
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbItemActive {
      display: flex;
margin: 0px;
color: #3b82f6;
    }
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbDivider {
      margin-left: 10px;
margin-right: 10px;
    }
      
    
[data-page-element="ProgressBar/V1"] {
      margin-top: 12px;
    }
      [data-page-element="ProgressBar/V1"] .progress {
      overflow: hidden;
background: #FCFCFC;
    }
[data-page-element="ProgressBar/V1"] .progress-bar {
      height: 28px;
margin: 0px;
transition: width 0.2s ease 0s;
display: flex;
align-items: center;
background: #307CED;
    }
[data-page-element="ProgressBar/V1"] .progress-label {
      color: rgb(255, 255, 255);
font-weight: 600;
text-align: left;
white-space: nowrap;
width: 100%;
padding: 0 8px;
    }
[data-page-element="ProgressBar/V1"] > .progress-label {
      color: #353535;
    }
      
    
[data-page-element="TextArea/V1"] {
      margin: 0px auto;
margin-top: 15px;
font-family: inherit;
position: relative;
padding: 0 !important;
--label-padding-on-focus: 4px;
border: 0 !important;
width: 100%;
    }
      [data-page-element="TextArea/V1"] .elTextarea {
      font-family: inherit;
width: 100%;
height: 120px;
font-size: 12px;
background: transparent;
border: 0;
vertical-align: middle;
resize: none;
z-index: 1;
position: relative;
    }
[data-page-element="TextArea/V1"] .elTextarea::-moz-placeholder {
      opacity: 0;
font-size: 12px;
color: rgb(96, 96, 107, 80%);
    }
[data-page-element="TextArea/V1"] .elTextarea::placeholder {
      opacity: 0;
font-size: 12px;
color: rgb(96, 96, 107, 80%);
    }
[data-page-element="TextArea/V1"] .elLabel {
      position: absolute;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
pointer-events: none;
border-radius: inherit;
display: flex;
border-width: inherit;
    }
[data-page-element="TextArea/V1"] label {
      top: 0;
position: relative;
transform: translateY(-50%);
display: block;
transition: all .2s ease;
padding-top: inherit;
border-bottom-color: transparent !important;
    }
[data-page-element="TextArea/V1"] .borderHolder {
      margin: 0 !important;
box-shadow: none !important;
background: #ffffff;
    }
[data-page-element="TextArea/V1"] .inputHolder {
      background: transparent;
border-radius: inherit;
padding-bottom: 0px;
padding-top: 0px;
border-color: transparent !important;
    }
[data-page-element="TextArea/V1"] div.inputHolder {
      padding-bottom: 0!important;
padding-top: 0!important;
    }
[data-page-element="TextArea/V1"].elInputFocused .elTextarea::-moz-placeholder, [data-page-element="TextArea/V1"].hasValue .elTextarea::-moz-placeholder {
      opacity: 1;
    }
[data-page-element="TextArea/V1"].elInputFocused .elTextarea::placeholder,
[data-page-element="TextArea/V1"].hasValue .elTextarea::placeholder {
      opacity: 1;
    }
[data-page-element="TextArea/V1"] .elLabel,
[data-page-element="TextArea/V1"] .elTextarea {
      text-align: left;
    }
[data-page-element="TextArea/V1"].labelInside .borderHolder {
      display: none;
    }
[data-page-element="TextArea/V1"].labelInside .labelText {
      display: block;
width: 100%;
    }
[data-page-element="TextArea/V1"].labelInside.elInputFocused label,
[data-page-element="TextArea/V1"].labelInside.hasValue label {
      top: 0px;
    }
[data-page-element="TextArea/V1"].labelInside.elInputFocused .elInput,
[data-page-element="TextArea/V1"].labelInside.hasValue .elInput {
      transform: translateY(15%);
    }
[data-page-element="TextArea/V1"].labelInside.elInputFocused .labelText {
      font-size: 10px;
    }
[data-page-element="TextArea/V1"].labelInside.hasValue .labelText {
      font-size: 10px;
    }
[data-page-element="TextArea/V1"].labelOnBorder .labelText {
      flex: 0 0 auto;
width: auto;
color: rgb(21, 21, 23);
padding-left: var(--label-padding-on-focus) !important;
padding-right: var(--label-padding-on-focus) !important;
border-right: none !important;
border-left: none !important;
flex-grow: 0 !important;
padding-bottom: 0 !important;
border-radius: 0 !important;
font-size: 14px;
    }
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused .labelText {
      font-size: 12px;
    }
[data-page-element="TextArea/V1"].labelOnBorder.hasValue .labelText {
      font-size: 12px;
    }
[data-page-element="TextArea/V1"].labelOnBorder .borderHolder:last-child {
      border-left: none !important;
flex-grow: 1;
border-top-left-radius: 0 !important;
border-bottom-left-radius: 0 !important;
padding-left: 0 !important;
padding-top: 0 !important;
padding-bottom: 0 !important;
    }
[data-page-element="TextArea/V1"].labelOnBorder .borderHolder:first-child {
      border-right: none !important;
border-top-right-radius: 0 !important;
border-bottom-right-radius: 0 !important;
    }
[data-page-element="TextArea/V1"].labelOnBorder .elLabel .borderHolder:first-child {
      padding-right: 0 !important;
    }
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused .borderHolder,
[data-page-element="TextArea/V1"].labelOnBorder.hasValue .borderHolder {
      padding-top: 0 !important;
    }
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused .labelText,
[data-page-element="TextArea/V1"].labelOnBorder.hasValue .labelText {
      border-top-color: transparent !important;
    }
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused label,
[data-page-element="TextArea/V1"].labelOnBorder.hasValue label {
      top: 0px;
border-bottom: inherit;
    }
      
    
[data-page-element="AddToCart"] {
      display: flex;
flex-direction: column;
gap: 15px;
align-items: start;
justify-content: center;
padding-top: 10px;
padding-bottom: 10px;
font-family: var(--text-font-family);
padding-left: 15px;
padding-right: 15px;
    }
      [data-page-element="AddToCart"] .elAddToCartButtonsWrapper {
      display: flex;
flex-direction: column;
gap: 10px;
width: 100%;
    }
[data-page-element="AddToCart"] [data-page-element="Button/V1"] {
      margin: 0px;
    }
[data-page-element="AddToCart"] [data-page-element="Button/V1"] .elButton {
      width: 100%;
    }

      [data-page-element="AddToCart"] .elAddToCartButtonExtraSmall .elButton {
      padding-left: 10px;
padding-right: 10px;
padding-top: 8px;
padding-bottom: 8px;
    }
      [data-page-element="AddToCart"] .elAddToCartButtonExtraSmall .elButton .elButtonText {
      font-size: 12px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartButtonSmall .elButton {
      padding-left: 12px;
padding-right: 12px;
padding-top: 10px;
padding-bottom: 10px;
    }
      [data-page-element="AddToCart"] .elAddToCartButtonSmall .elButton .elButtonText {
      font-size: 13px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartButtonMedium .elButton {
      padding-left: 12px;
padding-right: 12px;
padding-top: 12px;
padding-bottom: 12px;
    }
      [data-page-element="AddToCart"] .elAddToCartButtonMedium .elButton .elButtonText {
      font-size: 13px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartButtonLarge .elButton {
      padding-left: 12px;
padding-right: 12px;
padding-top: 14px;
padding-bottom: 14px;
    }
      [data-page-element="AddToCart"] .elAddToCartButtonLarge .elButton .elButtonText {
      font-size: 14px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartButtonExtraLarge .elButton {
      padding-left: 14px;
padding-right: 14px;
padding-top: 16px;
padding-bottom: 16px;
    }
      [data-page-element="AddToCart"] .elAddToCartButtonExtraLarge .elButton .elButtonText {
      font-size: 14px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartAddToCartButton .elButton {
      background: var(--button-primary-background-color);
    }
[data-page-element="AddToCart"] .elAddToCartAddToCartButton .elButtonText {
      color: var(--button-primary-text-color);
    }
[data-page-element="AddToCart"] .elAddToCartAddToCartButton .elButton:hover .elButtonText,
[data-page-element="AddToCart"] .elAddToCartAddToCartButton .elButton.elButtonHovered .elButtonText {
      color: var(--button-primary-text-color);
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartBuyNowButton .elButton {
      background: transparent;
border-style: solid;
border-color: var(--button-secondary-color);
border-width: 1px;
    }
[data-page-element="AddToCart"] .elAddToCartBuyNowButton .elButtonText {
      color: var(--button-secondary-color);
    }
[data-page-element="AddToCart"] .elAddToCartBuyNowButton .elButton:hover .elButtonText,
[data-page-element="AddToCart"] .elAddToCartBuyNowButton .elButton.elButtonHovered .elButtonText {
      color: var(--button-secondary-color);
    }
      
    
[data-page-element="AddToCart"] .elAddToCartSinglePriceWrapper {
      margin: 0px;
display: flex;
gap: 10px;
    }
[data-page-element="AddToCart"] .elSelectWrapper {
      margin: 0px;
    }
[data-page-element="AddToCart"] .elSelectWrapper select {
      color: var(--input-text-color);
font-family: var(--text-font-family);
border-color: var(--input-border-color);
border-style: solid;
border-width: 1px;
    }
[data-page-element="AddToCart"] .elSelectArrow {
      color: var(--input-text-color);
    }

      [data-page-element="AddToCart"] .elAddToCartInputExtraSmall select {
      padding-left: 10px;
padding-right: 35px;
font-size: 12px;
padding-top: 8px;
padding-bottom: 8px;
    }
[data-page-element="AddToCart"] .elAddToCartInputExtraSmall .elSelectArrow {
      font-size: 13px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartInputSmall select {
      padding-left: 10px;
padding-right: 35px;
font-size: 12px;
padding-top: 10px;
padding-bottom: 10px;
    }
[data-page-element="AddToCart"] .elAddToCartInputSmall .elSelectArrow {
      font-size: 13px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartInputMedium select {
      padding-left: 10px;
padding-right: 35px;
font-size: 13px;
padding-top: 12px;
padding-bottom: 12px;
    }
[data-page-element="AddToCart"] .elAddToCartInputMedium .elSelectArrow {
      font-size: 14px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartInputLarge select {
      padding-left: 12px;
padding-right: 40px;
font-size: 14px;
padding-top: 14px;
padding-bottom: 14px;
    }
[data-page-element="AddToCart"] .elAddToCartInputLarge .elSelectArrow {
      font-size: 15px;
right: 14px;
    }
      
    

      [data-page-element="AddToCart"] .elAddToCartInputExtraLarge select {
      padding-left: 14px;
padding-right: 40px;
font-size: 14px;
padding-top: 16px;
padding-bottom: 16px;
    }
[data-page-element="AddToCart"] .elAddToCartInputExtraLarge .elSelectArrow {
      font-size: 15px;
right: 14px;
    }
      
    
[data-page-element="AddToCart"] .elAddToCartVariantSelectorWrapper {
      margin: 0px;
width: 100%;
display: flex;
flex-direction: column;
gap: 5px;
    }
      [data-page-element="AddToCart"] .elAddToCartVariantSelectorWrapper select {
      width: 100%;
    }
      
    
[data-page-element="AddToCart"] .elAddToCartName {
      font-size: 28px;
color: var(--style-headline-color, #000);
font-weight: 600;
    }
[data-page-element="AddToCart"] .elAddToCartPrice {
      color: var(--style-subheadline-color, #000);
    }
[data-page-element="AddToCart"] .elAddToCartCompareAt {
      color: var(--style-subheadline-color, rgb(0, 0, 0, 0.5));
text-decoration: line-through;
    }
[data-page-element="AddToCart"] .elAddToCartPrice,
[data-page-element="AddToCart"] .elAddToCartCompareAt {
      font-size: 18px;
    }
[data-page-element="AddToCart"] .elAddToCartDescription {
      font-size: 14px;
color: var(--style-color, #000);
    }
[data-page-element="AddToCart"] .elAddToCartVariantSelectorTitle {
      font-size: 14px;
color: var(--style-link-color, #000);
display: block;
margin-bottom: 5px;
    }
      
    
.elAddToCart {
      --button-primary-background-color: var(--style-link-color, #000);
      --button-primary-text-color: #fff;
      --button-secondary-color: var(--style-link-color, #000);
      --input-text-color: var(--style-link-color, #000);
      --input-border-color: var(--style-link-color, #000);
      --text-font-family: sans-serif;
   }

      
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    
[data-page-element="CoursePodcastLink/V1"] {
      text-align: center;
    }
      [data-page-element="CoursePodcastLink/V1"] .elImageWrapper {
      padding: 0px;
    }
[data-page-element="CoursePodcastLink/V1"] .elPodcastLink:hover {
      text-decoration: none;
    }
[data-page-element="CoursePodcastLink/V1"] .elPodcastLink {
      display: inline-flex;
color: black;
    }
[data-page-element="CoursePodcastLink/V1"] .elPodcastLinkText {
      margin-left: 5px;
display: -webkit-inline-flex;
-webkit-box-orient: vertical;
-webkit-box-pack: center;
-webkit-flex-pack: center;
-webkit-justify-content: center;
-webkit-flex-align: center;
-webkit-align-items: center;
vertical-align: top;
    }
[data-page-element="CoursePodcastLink/V1"] .elPodcastLinkImage {
      display: inline-block;
width: 30px;
margin-top: 0px;
    }
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    
[data-page-element="CoursesSectionPodcastLink/V1"] {
      text-align: center;
    }
      [data-page-element="CoursesSectionPodcastLink/V1"] .elImageWrapper {
      padding: 0px;
    }
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLink:hover {
      text-decoration: none;
    }
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLink {
      display: inline-flex;
color: black;
    }
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLinkText {
      margin-left: 5px;
display: -webkit-inline-flex;
-webkit-box-orient: vertical;
-webkit-box-pack: center;
-webkit-flex-pack: center;
-webkit-justify-content: center;
-webkit-flex-align: center;
-webkit-align-items: center;
vertical-align: top;
    }
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLinkImage {
      display: inline-block;
width: 30px;
margin-top: 0px;
    }
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    

      
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="Image/V2"][data-dynamic-image] .elImage {
      aspect-ratio: 16 / 9;
-o-object-fit: scale-down;
   object-fit: scale-down;
-o-object-position: center;
   object-position: center;
    }
      
    

      
      
    

      
      
    

      
      
    

      [data-page-element="ProductsCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="ProductsCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="ProductsCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="ProductCollectionsCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="ProductCollectionsCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="ProductCollectionsCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="BlogPostsCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="BlogPostsCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="BlogPostsCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItemActive,
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="BlogCategoriesCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="BlogCategoriesCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="GrantedAssetsCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="GrantedAssetsCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="CoursesCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemActive,
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="CoursesCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="CoursesCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="CourseSectionsCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="CourseSectionsCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItem {
      padding: 8px 16px;
font-size: 16px;
border-radius: 4px;
margin-left: 5px;
margin-right: 5px;
text-decoration: none;
border-style: solid;
border-width: 1px;
    }
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItemActive:visited {
      color: white;
    }
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItemActive {
      background-color: blue;
border-color: black;
    }
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItemNotActive:visited {
      color: black;
    }
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItemNotActive {
      background-color: white;
border-color: black;
    }
[data-page-element="CourseLessonsCollection/V1"] .innerCollectionContent {
      padding: 5px;
width: 100%;
    }
[data-page-element="CourseLessonsCollection/V1"] .collectionPagination {
      display: flex;
align-items: center;
justify-content: center;
    }
      
    

      [data-page-element="CourseProgressBar/V1"] .progress {
      background: #FCFCFC;
    }
[data-page-element="CourseProgressBar/V1"] .progress-bar {
      background: #307CED;
    }
[data-page-element="CourseProgressBar/V1"] .progress-label {
      color: rgb(255, 255, 255);
font-weight: 600;
text-align: left;
white-space: nowrap;
width: 100%;
padding: 0 8px;
    }
[data-page-element="CourseProgressBar/V1"] .elProgressBar > .progress-label {
      color: #353535;
    }
      
    

      [data-page-element="LessonStatus"] .elTypographyLink.elTypographyLink {
      color: inherit;
    }
      
    

      
      
    

      
      
    
[data-page-element="BlogPostCategories/V1"] {
      gap: 1.5em;
display: flex;
    }
      [data-page-element="BlogPostCategories/V1"] div,
[data-page-element="BlogPostCategories/V1"] a,
[data-page-element="BlogPostCategories/V1"] i {
      margin: 0px;
    }
[data-page-element="BlogPostCategories/V1"][data-direction="row"] {
      flex-direction: row;
align-items: center;
justify-content: var(--layout-justification-setting);
    }
[data-page-element="BlogPostCategories/V1"][data-direction="column"] {
      flex-direction: column;
align-items: var(--layout-justification-setting);
justify-content: center;
    }
      
    
[data-page-element="BlogPostAuthors/V1"] {
      gap: 1.5em;
display: flex;
    }
      [data-page-element="BlogPostAuthors/V1"] div,
[data-page-element="BlogPostAuthors/V1"] a,
[data-page-element="BlogPostAuthors/V1"] i {
      margin: 0px;
    }
[data-page-element="BlogPostAuthors/V1"][data-direction="row"] {
      flex-direction: row;
align-items: center;
justify-content: var(--layout-justification-setting);
    }
[data-page-element="BlogPostAuthors/V1"][data-direction="column"] {
      flex-direction: column;
align-items: var(--layout-justification-setting);
justify-content: center;
    }
      
    
[data-page-element="TemplateDynamicSection"] {
      width: 100%;
    }

      [data-page-element="StoreSearchProducts"] {
      display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-auto-rows: 1fr;
grid-gap: 40px;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="StoreSearchProducts"] .elStoreSearchProductCard {
      display: flex;
gap: 24px;
width: 100%;
    }
[data-page-element="StoreSearchProducts"] .elImage {
      min-width: 78px;
min-height: 78px;
max-width: 78px;
max-height: 78px;
-o-object-fit: cover;
   object-fit: cover;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="StoreSearchProducts"] .elImageWrapper {
      margin-top: 0px;
    }
[data-page-element="StoreSearchProducts"] .elStoreSearchProductCardInfo {
      width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
    }
[data-page-element="StoreSearchProducts"] .elStoreSearchProductCardName {
      text-align: left;
color: var(--text-color);
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="StoreSearchProducts"] .elStoreSearchProductCardPrice {
      color: var(--text-color);
opacity: 0.5;
    }
[data-page-element="StoreSearchProducts"] .elStoreSearchHighlight {
      font-weight: 600;
    }
      @media (max-width: 992px) {
        
      [data-page-element="StoreSearchProducts"] {
      grid-template-columns: 1fr 1fr;
grid-gap: 30px;
    }
      
    
      }
@media (max-width: 600px) {
        
      [data-page-element="StoreSearchProducts"] {
      grid-template-columns: 1fr;
grid-gap: 20px;
    }
      
    
      }
    

      [data-page-element="StoreSearch"] {
      text-align: center;
--loading-spinner-color: gray;
--modal-color: white;
--text-color: black;
    }
[data-page-element="StoreSearch"] .elStoreSearchIcon {
      font-size: 24px;
    }
      [data-page-element="StoreSearch"] .elStoreSearchIcon i {
      color: black;
    }
      
    
[data-page-element="StoreSearch"] .elModal {
      background: var(--modal-color);
    }
[data-page-element="StoreSearch"] .elModalInnerContainer,
[data-page-element="StoreSearch"] .elModalInner {
      width: 100%;
height: 100%;
    }
[data-page-element="StoreSearch"] .elModalClose {
      font-size: 24px;
cursor: pointer;
    }
[data-page-element="StoreSearch"] .elStoreSearch {
      width: 100%;
height: 100%;
gap: 80px;
display: flex;
flex-direction: column;
padding-left: 90px;
padding-right: 90px;
padding-top: 120px;
padding-bottom: 120px;
    }
[data-page-element="StoreSearch"] .elStoreSearchHeader {
      display: flex;
width: 100%;
align-items: center;
justify-content: space-between;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="StoreSearch"] .elStoreSearchHeader i {
      color: var(--text-color);
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="StoreSearch"] .elStoreSearchInputWrapper {
      display: flex;
align-items: center;
width: 60%;
max-width: 750px;
min-width: 400px;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="StoreSearch"] .elStoreSearchInputWrapper input {
      width: 100%;
background: #F4F4F4;
color: var(--text-color);
outline: none;
border-style: solid;
border-color: #D2D2D2;
border-width: 1px;
    }
[data-page-element="StoreSearch"] .elStoreSearchInputWrapper input::-moz-placeholder {
      color: var(--text-color);
    }
[data-page-element="StoreSearch"] .elStoreSearchInputWrapper input::placeholder {
      color: var(--text-color);
    }
[data-page-element="StoreSearch"] .elStoreSearchInputWrapper i {
      margin-left: -36px;
color: var(--text-color);
    }
[data-page-element="StoreSearch"] .elStoreSearchBody {
      display: flex;
flex-direction: column;
align-items: center;
width: 100%;
    }
[data-page-element="StoreSearch"] .elStoreSearchLoading {
      display: flex;
width: 100%;
align-items: center;
justify-content: center;
    }
[data-page-element="StoreSearch"] .elSpinnerRing div {
      border: 8px solid var(--loading-spinner-color);
border-color: var(--loading-spinner-color) transparent transparent transparent;
    }
[data-page-element="StoreSearch"] .elStoreSearchProductsWrapper {
      width: 100%;
display: flex;
flex-direction: column;
gap: 60px;
color: black;
    }
[data-page-element="StoreSearch"] .elStoreSearchTitle {
      font-size: 28px;
text-align: center;
font-weight: 600;
color: var(--text-color);
    }
[data-page-element="StoreSearch"] .elStoreSearchResultsNotFound {
      font-size: 18px;
text-align: center;
    }

      [data-page-element="StoreSearch"] [data-store-search-size="xs"] .elStoreSearchInputWrapper input {
      font-size: 13px;
padding-left: 12px;
padding-right: 12px;
padding-top: 12px;
padding-bottom: 12px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xs"] .elModalClose {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xs"] .elStoreSearchInputWrapper i {
      font-size: 14px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xs"] .elStoreSearchTitle {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xs"] .elStoreSearchResultsNotFound {
      font-size: 13px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xs"] .elStoreSearchProductCardName {
      font-size: 13px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xs"] .elStoreSearchProductCardPrice {
      font-size: 12px;
    }
      
    

      [data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchInputWrapper input {
      font-size: 14px;
padding-left: 13px;
padding-right: 13px;
padding-top: 13px;
padding-bottom: 13px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elModalClose {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchInputWrapper i {
      font-size: 16px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchTitle {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchResultsNotFound {
      font-size: 14px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchProductCardName {
      font-size: 14px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchProductCardPrice {
      font-size: 13px;
    }
      @media (max-width: 768px) {
        
      [data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchInputWrapper input {
      font-size: 13px;
padding-left: 12px;
padding-right: 12px;
padding-top: 12px;
padding-bottom: 12px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elModalClose {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchInputWrapper i {
      font-size: 14px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchTitle {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchResultsNotFound {
      font-size: 13px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchProductCardName {
      font-size: 13px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="s"] .elStoreSearchProductCardPrice {
      font-size: 12px;
    }
      
    
      }
    

      [data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchInputWrapper input {
      font-size: 16px;
padding-left: 15px;
padding-right: 15px;
padding-top: 15px;
padding-bottom: 15px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elModalClose {
      font-size: 22px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchInputWrapper i {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchTitle {
      font-size: 24px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchResultsNotFound {
      font-size: 16px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchProductCardName {
      font-size: 16px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchProductCardPrice {
      font-size: 14px;
    }
      @media (max-width: 768px) {
        
      [data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchInputWrapper input {
      font-size: 14px;
padding-left: 13px;
padding-right: 13px;
padding-top: 13px;
padding-bottom: 13px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elModalClose {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchInputWrapper i {
      font-size: 16px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchTitle {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchResultsNotFound {
      font-size: 14px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchProductCardName {
      font-size: 14px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="m"] .elStoreSearchProductCardPrice {
      font-size: 13px;
    }
      
    
      }
    

      [data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchInputWrapper input {
      font-size: 18px;
padding-left: 17px;
padding-right: 17px;
padding-top: 17px;
padding-bottom: 17px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elModalClose {
      font-size: 24px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchInputWrapper i {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchTitle {
      font-size: 28px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchResultsNotFound {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchProductCardName {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchProductCardPrice {
      font-size: 16px;
    }
      @media (max-width: 768px) {
        
      [data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchInputWrapper input {
      font-size: 16px;
padding-left: 15px;
padding-right: 15px;
padding-top: 15px;
padding-bottom: 15px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elModalClose {
      font-size: 22px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchInputWrapper i {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchTitle {
      font-size: 24px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchResultsNotFound {
      font-size: 16px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchProductCardName {
      font-size: 16px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="l"] .elStoreSearchProductCardPrice {
      font-size: 14px;
    }
      
    
      }
    

      [data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchInputWrapper input {
      font-size: 20px;
padding-left: 18px;
padding-right: 18px;
padding-top: 18px;
padding-bottom: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elModalClose {
      font-size: 28px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchInputWrapper i {
      font-size: 22px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchTitle {
      font-size: 32px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchResultsNotFound {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchProductCardName {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchProductCardPrice {
      font-size: 18px;
    }
      @media (max-width: 768px) {
        
      [data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchInputWrapper input {
      font-size: 18px;
padding-left: 17px;
padding-right: 17px;
padding-top: 17px;
padding-bottom: 17px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elModalClose {
      font-size: 24px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchInputWrapper i {
      font-size: 20px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchTitle {
      font-size: 28px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchResultsNotFound {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchProductCardName {
      font-size: 18px;
    }
[data-page-element="StoreSearch"] [data-store-search-size="xl"] .elStoreSearchProductCardPrice {
      font-size: 16px;
    }
      
    
      }
      @media (max-width: 1200px) {
        
      [data-page-element="StoreSearch"] .elStoreSearch {
      gap: 60px;
padding-left: 45px;
padding-right: 45px;
padding-top: 60px;
padding-bottom: 60px;
    }
[data-page-element="StoreSearch"] .elStoreSearchProductsWrapper {
      gap: 40px;
    }
      
    
      }
@media (max-width: 768px) {
        
      [data-page-element="StoreSearch"] .elStoreSearchInputWrapper {
      width: 100%;
max-width: 100%;
min-width: 100%;
    }
[data-page-element="StoreSearch"] .elStoreSearch {
      gap: 45px;
padding-left: 24px;
padding-right: 24px;
padding-top: 16px;
padding-bottom: 16px;
    }
[data-page-element="StoreSearch"] .elStoreSearchHeader {
      flex-direction: column-reverse;
align-items: flex-end;
gap: 30px;
    }
[data-page-element="StoreSearch"] .elStoreSearchHeader > div:first-of-type {
      display: none;
    }
[data-page-element="StoreSearch"] .elStoreSearchProductsWrapper {
      gap: 30px;
    }
      
    
      }
    
[data-page-element="CheckoutProductImageCarousel/V1"] {
      padding-left: 10px;
padding-right: 10px;
    }
      [data-page-element="CheckoutProductImageCarousel/V1"] {
      position: relative;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .elCheckoutProductImageCarouselContainer {
      --swiper-scrollbar-bg-color: color-mix(in srgb, var(--swiper-scrollbar-drag-bg-color) 10%, transparent);;
--swiper-pagination-color: #aaa;
--swiper-navigation-color: #aaa;
position: relative;
padding-top: 10px;
overflow: hidden;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-slide {
      overflow: hidden;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-slide img {
      display: block;
width: 100%;
height: 100%;
-o-object-fit: contain;
   object-fit: contain;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav img {
      height: 100%;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav {
      height: 100px;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-main-wrapper {
      position: relative;
width: 100%;
height: 500px;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-main {
      height: 100%;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav-wrapper {
      padding: 15px 0px;
position: relative;
overflow: hidden;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav .swiper-slide {
      opacity: 0.4;
margin: 0px;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav .swiper-slide-thumb-active {
      opacity: 1;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-scrollbar {
      width: 100%;
z-index: 0;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-scrollbar-drag {
      margin-left: 0px;
    }
[data-page-element="CheckoutProductImageCarousel/V1"] .elProductImageCarouselOuterFrame,
[data-page-element="CheckoutProductImageCarousel/V1"] .elProductCarouselMainImageOuterFrame {
      display: block;
position: absolute;
top: 0px;
bottom: 0px;
left: 0px;
right: 0px;
background: transparent;
z-index: 2;
pointer-events: none;
    }
      
    
[data-page-element="OrderConfirmation/V1"] {
      margin-top: 15px;
position: relative;
padding-left: 10px;
padding-right: 10px;
    }
      [data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHead {
      height: 64px;
display: flex;
gap: 10px;
align-items: center;
width: 100%;
padding-left: 24px;
padding-right: 24px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHeadIcon {
      color: #151D28;
font-size: 14px;
margin-right: 10px;
margin: 0px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationBody {
      display: flex;
align-items: center;
width: 100%;
height: 100%;
gap: 24px;
flex-direction: column;
padding-top: 24px;
padding-bottom: 24px;
padding-left: 24px;
padding-right: 24px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductInfoName {
      font-size: 14px;
color: #2D2D2D;
font-weight: 600;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductInfo {
      font-size: 14px;
color: #364557;
font-weight: 400;
margin: 0px;
display: flex;
gap: 20px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProduct {
      width: 100%;
display: flex;
align-items: center;
gap: 16px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductImage {
      display: flex;
align-items: center;
justify-content: center;
width: 30%;
margin: 0px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductImage img {
      width: 100%;
height: 100%;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductInfoWrapper {
      margin: 0px;
display: flex;
flex-direction: column;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHead,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationFoot,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationBody {
      border-style: solid;
border-color: #ECF0F5;
border-bottom-width: 1px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationTotal {
      font-size: 16px;
font-weight: 600;
color: #2D2D2D;
height: 64px;
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
padding-left: 24px;
padding-right: 24px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHeadTitle {
      font-size: 16px;
color: #151D28;
font-weight: 500;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationFoot {
      padding-top: 18px;
padding-bottom: 18px;
padding-left: 24px;
padding-right: 24px;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationFoot .elOrderConfirmationItem {
      font-weight: 400;
font-size: 15px;
color: #364557;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationFoot .elOrderConfirmationPrice {
      font-weight: 500;
font-size: 15px;
color: #151D28;
    }
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationTaxTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationSubTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationShippingTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationDiscountTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationInvoiceLineItem {
      display: flex;
height: 35px;
align-items: center;
width: 100%;
justify-content: space-between;
    }
      @media (max-width: 770px) {
        
      [data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductImage {
      width: 30%;
    }
      
    
      }
    
[data-page-element="SurveyImageOption/V1"] {
      margin: 0px;
border: 1px solid rgba(var(--style-link-color-r), var(--style-link-color-g), var(--style-link-color-b), 25%);
border-radius: 6px;
margin-bottom: 16px;
background-color: rgba(var(--style-link-color-r), var(--style-link-color-g), var(--style-link-color-b), 15%);
box-shadow: 0 4px 6px rgba(var(--style-link-color-r), var(--style-link-color-g), var(--style-link-color-b), 15%);
padding: 12px;
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
height: 100%;
cursor: pointer;
    }
      [data-page-element="SurveyImageOption/V1"]:hover {
      background-color: rgba(var(--style-link-color-r), var(--style-link-color-g), var(--style-link-color-b), 30%);
    }
      [data-page-element="SurveyImageOption/V1"]:hover .elRadioWrapper:hover,
[data-page-element="SurveyImageOption/V1"]:hover .elCheckboxWrapper:hover {
      background-color: unset;
    }
      
    
[data-page-element="SurveyImageOption/V1"] .elCheckboxWrapper:hover .elCheckboxText,
[data-page-element="SurveyImageOption/V1"] .elRadioWrapper:hover .elRadioText {
      color: var(--answer-color) !important;
    }
[data-page-element="SurveyImageOption/V1"]:has(input:checked) {
      border: 1px solid var(--style-link-color);
    }
[data-page-element="SurveyImageOption/V1"] [data-page-element="Checkbox/V1"] {
      margin: 0;
    }
[data-page-element="SurveyImageOption/V1"] .elSurveyImageOptionBase__Image {
      position: relative;
padding-bottom: 56.25%;
width: 100%;
    }
[data-page-element="SurveyImageOption/V1"] .elSurveyImageOptionBase__Image img {
      -o-object-fit: contain;
         object-fit: contain;
width: 100%;
position: absolute;
box-sizing: inherit;
height: 100%;
top: 0px;
left: 0px;
    }
[data-page-element="SurveyImageOption/V1"] .elSurveyImageOptionBase__Input {
      display: flex;
align-items: center;
height: 100%;
width: 100%;
    }
      
    

      
      
    

      
      
    
.dynamic-content-placeholder {
    height: 350px;
    background-image: url("/editor/dynamiccontentplaceholder.png");
    background-size: 100% 100%;
    padding: 15px;
    text-align: center;
    cursor: help;
  }
  .dynamic-content-placeholder-header {
    color: grey;
  }