import { CF2Component } from 'javascript/lander/runtime'
import { debounce } from '../../Utils/general'

let fuse
const allProductsById = {}

const getCheapestPrice = (prices) => {
  return prices.reduce(
    (acc, value) => {
      if (acc.price_cents > value.price_cents) {
        acc.price_cents = value.price_cents
        acc.price = value.price
      }

      return acc
    },
    { price_cents: Infinity, price: '' }
  )
}

const parseProducts = (products) => {
  return products.map((p) => {
    let cheapestPrice = getCheapestPrice(p.default_variant.prices)
    if (p.variants.length > 1) {
      cheapestPrice = p.variants.reduce(
        (acc, value) => {
          const currentVariantCheapestPrice = getCheapestPrice(value.prices)
          if (currentVariantCheapestPrice.price_cents < acc.price_cents) {
            acc.price_cents = currentVariantCheapestPrice.price_cents
            acc.price = currentVariantCheapestPrice.price
          }

          return acc
        },
        { ...cheapestPrice }
      )
    }
    return { id: p.id, name: p.name, description: p.description, price: cheapestPrice.price }
  })
}

const prepareProductsForSearch = (component: CF2Component) => {
  return globalThis
    .CFFetch(
      '/cfworker/api/products/search',
      {
        headers: { 'Content-Type': 'application/json' },
      },
      { retries: 3, shouldCaptureServerError: true }
    )
    .then((res) => res.json())
    .then((json) => {
      const initialProducts = json.products
      if (!initialProducts || !initialProducts.length) return
      initialProducts.forEach((p) => (allProductsById[p.id] = p))
      const parsedProducts = parseProducts(initialProducts)
      fuse = new globalThis.Fuse(parsedProducts, {
        threshold: 0.3,
        keys: ['name', { name: 'description', weight: 0.5 }],
      })
      productsReady(component)
    })
}

const searchProducts = ([evt, component]) => {
  const search = (evt.target as HTMLInputElement).value
  if (search) {
    const foundProducts = fuse.search(search, { limit: 6 })
    if (foundProducts.length) {
      const products = foundProducts.map((p) => {
        const product = p.item
        const originalProduct = allProductsById[product.id]
        const image = originalProduct.image ?? originalProduct.variants.find((variant) => !!variant.image)?.image
        return {
          ...product,
          url: originalProduct.url,
          image: image,
        }
      })
      updateProducts(component, products)
      hideTitle(component)
      showSearchProducts(component, search)
    } else {
      hideProducts(component)
      hideTitle(component)
      showResultsNotFound(component, search)
    }
  } else {
    hideProducts(component)
    hideResultsNotFound(component)
    hideTitle(component)
  }
}

const debounceHandler = debounce(searchProducts, 700)

export const mountComponent = (component: CF2Component): void => {
  prepareProductsForSearch(component)
  const searchIcon = component.element.querySelector('[href="#open-store-search"]')
  searchIcon.addEventListener('click', () => showModal(component))

  const searchElement = component.element.querySelector('[name="store-search"]')
  searchElement.addEventListener('input', (evt) => debounceHandler(evt, component))
}

const getProductsElementComponent = (component: CF2Component) => {
  return component.getComponent('StoreSearchProducts')
}

const updateProducts = (component: CF2Component, products) => {
  ;(getProductsElementComponent(component) as any).search_products = products
  getProductsElementComponent(component).render()
}

const showModal = (component: CF2Component) => {
  component.getComponent('Modal/V1').element.style.display = 'flex'
}

const productsReady = (component: CF2Component) => {
  getProductsElementComponent(component).element.classList.remove('forceHide')
  component.element.querySelector('.elStoreSearchTitle').classList.remove('forceHide')
  ;(component.element.querySelector('[name="store-search"]') as HTMLInputElement).disabled = false
  component.element.querySelector('.elStoreSearchLoading').classList.add('forceHide')
}

const showSearchProducts = (component: CF2Component, search: string) => {
  const products = getProductsElementComponent(component).element.querySelectorAll('.elStoreSearchProductCard')
  products.forEach((elem) => {
    const element = elem.querySelector('.elStoreSearchProductCardName')
    const name = elem.querySelector('.elStoreSearchProductCardName').innerHTML
    const regex = new RegExp(search, 'i')
    const match = name.match(regex)
    if (match) {
      const replace = match[0]
      element.innerHTML = name.replace(replace, `<span class="elStoreSearchHighlight">${replace}</span>`)
    }
  })
  getProductsElementComponent(component).element.classList.remove('forceHide')
}

const hideProducts = (component: CF2Component) => {
  getProductsElementComponent(component).element.classList.add('forceHide')
}

const hideTitle = (component: CF2Component) => {
  component.element.querySelector('.elStoreSearchTitle').classList.add('forceHide')
}

const showResultsNotFound = (component: CF2Component, text: string) => {
  const [split1, split2] = (component as any).not_found_text.split('{search}')
  component.element.querySelector('.elStoreSearchResultsNotFound').innerHTML = split1 + text + split2
  component.element.querySelector('.elStoreSearchResultsNotFound').classList.remove('forceHide')
}

const hideResultsNotFound = (component: CF2Component) => {
  component.element.querySelector('.elStoreSearchResultsNotFound').classList.add('forceHide')
}
