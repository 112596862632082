
import "./modal-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class ModalContainerV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.isAudioInitialized = false;
    this.modal = this.getComponent("Modal/V1")
    this.videoElements = this.getComponents("Video/V1")
    this.modalElement = this.modal.element
    this.containerModal = document.getElementById("modalPopup")
    this.showOnExit = this.containerModal.getAttribute("data-show-popup-on-exit") === "true"
    this.showOnLoad = this.containerModal.getAttribute("data-show-popup-on-page-load") === "true"
    this.showWhenElementIsVisible = this.element.getAttribute("data-selected-element")

    if (this.showWhenElementIsVisible) {
      const visibleElementSelector = this.showWhenElementIsVisible.startsWith('id-') ? `.${this.showWhenElementIsVisible}` : `.id-${this.showWhenElementIsVisible}`;
      this.visibleElement = document.querySelector(visibleElementSelector);
    }
    const self = this;

    this.modal.close = function () {
      handleModalClose();
    };

    if (this.visibleElement) {
      handleShowWhenElementIsVisible(this.visibleElement);
    }

    if (this.showOnExit) {
      handleShowOnExit();
    }

    if (this.showOnLoad) {
      if (this.showOnLoadDelay) {
        setTimeout(() => {
          handleModalOpen();
        }, this.showOnLoadDelay);
      } else {
        handleModalOpen();
      }
    }

    function handleModalOpen() {
      const containerModal = self.containerModal;
      const modalElement = self.modalElement;
      $(containerModal).show();
      $(containerModal).css("top", -800);
      $(containerModal).addClass("popup-relative-position");
      $("body").addClass("remove-page-scroll");
      $(modalElement).show();

      if (!self.isAudioInitialized) {
        self.isAudioInitialized = true;
        setupAudioElements();
      }

      $(modalElement).css({
        opacity: 1,
        top: 0,
      });
      $(containerModal).animate(
        {
          opacity: 1,
          top: 0,
        },
        {
          duration: 200,
        }
      );
    }

    function handleModalClose() {
      const containerModal = self.containerModal;
      const modalElement = self.modalElement;
      $(containerModal).animate(
        {
          opacity: 0,
          top: -800,
        },
        {
          duration: 200,
          complete: function () {
            $(modalElement).css({
              opacity: 0,
              top: -800,
            });
            $(modalElement).hide();
            $(containerModal).hide();
            $(containerModal).removeClass("popup-relative-position");
          },
        }
      );
      // make the page scrollable again
      $("body").removeClass("remove-page-scroll");
    }

    function handleShowWhenElementIsVisible() {
      const config = {
        rootMargin: "50px 20px 75px 30px",
        threshold: [0, 0.25, 0.75, 1],
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target, observer);
            handleModalOpen();
          }
        });
      }, config);

      observer.observe(self.visibleElement);
    }

    function handleShowOnExit() {
      let cfMouseP = 0;
      const modalElement = self.modalElement;

      const handleMouseMove = (evt) => {
        cfMouseP = evt.clientY;
      };

      const handleMouseLeave = () => {
        if (cfMouseP <= 40) {
          if (!$(modalElement).is(":visible")) {
            handleModalOpen();
            document
              .querySelector("body")
              .removeEventListener("mouseleave", handleMouseLeave);
            document
              .querySelector("body")
              .removeEventListener("mousemove", handleMouseMove);
          }
        }
      };
      document
        .querySelector("body")
        .addEventListener("mousemove", handleMouseMove);
      document
        .querySelector("body")
        .addEventListener("mouseleave", handleMouseLeave);
    }

    function setupAudioElements() {
      const audioElements = $(".containerModal .elAudioWrapper");
      try {
        audioElements.each(function () {
          const $this = $(this);
          const audioURL =
            $this.attr("data-audio-url") ||
            "https://images.clickfunnels.com/images/SevenNationArmy.mp3";
          const loop = $this.attr("data-audio-loop") || "no";

          const playerOptions = {
            class: "elAudioElement",
            audioWidth: "100%",
            audioHeight: "100%",
            enableAutosize: true,
            enableProgressTooltip: false,
            loop: loop === "yes",
            features: [
              "playpause",
              "current",
              "progress",
              "duration",
              "volume",
            ],
          };

          const audio = $(this).find("audio");
          audio.attr("src", audioURL);
          audio.mediaelementplayer(playerOptions);
        });
      } catch (err) {
        console.log(err);
      }
    }

    globalThis.CFOpenPopup = handleModalOpen;
    globalThis.CFClosePopup = handleModalClose;
  }



}

window["ModalContainerV1"] = ModalContainerV1

