;(function ($) {
  $.fn.changeElementType = function (newType) {
    var attrs = {}

    $.each(this[0].attributes, function (idx, attr) {
      attrs[attr.nodeName] = attr.nodeValue
    })

    this.replaceWith(function () {
      return $('<' + newType + '/>', attrs).append($(this).contents())
    })
  }
})(jQuery)
