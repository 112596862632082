import { Cookies } from '@clickfunnels2/cfhoy.js'

document.addEventListener('DOMContentLoaded', function (event) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const workspace_sharing_id = urlParams.get('workspace_sharing_id')

  if (workspace_sharing_id) {
     // 30 days * 24 hours * 60 minutes
     // 1 month expiration
    const ttl = 30 * 24 * 60
    Cookies.set('workspace_sharing_id', workspace_sharing_id, ttl)
  }
})
