.de-editable {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.highlight {
  background-color: #ffff88;
}
.addthisevent-drop {
  width: 100% !important;
}
.de-add-new-element-empty {
  display: none;
}
.wideCountdown-demo {
  display: none;
}
html {
  margin-right: 0px !important;
  overflow-x: hidden; /*color: #333;*/
}
body {
  color: #2e2e2e;
}
.containerWithVisibleOverflow {
  overflow: visible;
}
[data-trigger="load"] {
  opacity: 0;
  position: relative;
}
/* .stickyTop {
  z-index: 4;
}
.stickyTop.stuck {
  position: fixed;
  z-index: 3;
  margin-top: 0px !important;
  top: 0px;
}
.stickyBottom {
  position: fixed;
  bottom: 0px;
  z-index: 3;
} */
.stickyBottom {
  position: fixed;
  bottom: 0px;
}
.stickyClone {
  position: fixed!important;
  top: 0;
}
.stickyTop {
  position: relative;
}
.elVideoplaceholder,
.elAudioplaceholder {
  display: none;
}
.elVideo,
.elAudio {
  display: block !important;
}

.fb-comments {
  display: block !important;
}
.fbCommentsPlaceholder {
  display: none;
}
.pace .pace-progress {
  background: #cc181e;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;
  transition: width 1s;
}
.pace-inactive {
  display: none;
}
.nodoBadge {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
.btnIMG:hover {
  opacity: 0.8;
}
#closeCFPOPUP,
#closeCFPOPUP:hover {
  cursor: pointer !important;
}
.iframeblocked,
.iframeunmuted {
  position: relative;
}
.iframeBlocker,
.iframeUnmute {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%; /*background-color: rgba(0,);*/
  z-index: 5;
}

.start-session-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.start-session {
  color: #fff;
  font-weight: bold;
  font-size: 2.5em;
  line-height: normal;
}
.start-session-wrapper:hover {
  cursor: pointer;
}

.unmute-player-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.unmute-player-wrapper:after{
  content: var(--style-video-unmute-label-text);
}

.unmute-player-icon {
  color: #fff;
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.unmute-player-wrapper:hover {
  cursor: pointer;
}

.smsExtras {
  display: none;
}
.smsCheckArea {
  cursor: pointer;
  margin-bottom: 0px;
}
.smsCheck {
  margin-right: 10px;
}
.smsExtras {
  margin-top: 15px;
}
[data-element-link]:not([data-element-link=""]) {
  cursor: pointer;
}

/*  affiliate copy & paste ables */
.elAffCopyPaste:hover {
  border: 1px solid #0074c7;
  cursor: pointer;
}
.elAffCopyPaste:hover .elAffiliateAssetTitle {
  background-color: #0074c7;
  color: #fff;
}
.elAffBlack {
  display: none;
  position: absolute;
  background-color: rgba(0, 116, 199, 0.15);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px; /*z-index: 21;*/
}
.elAffCopyPaste:hover .elAffBlack {
  display: block;
}
.elAffCopyPaste .elAffBlack.showAffButtonRightAway {
  display: block !important;
}

/* membership hide register link */
#reset-form .goto-register {
  display: none;
}

/* survey steps */
.surveyRadioOption:hover {
  background-color: #def2fa;
}
.surveyRadioOption:hover .surveyRadioOptionText,
.surveyStepSelectedAnswer .surveyRadioOptionText {
  color: #1e4764 !important;
}
.surveyStepSelectedAnswer {
  background-color: #def2fa;
}
.smsCheckArea .smsCheck {
  position: relative;
  display: none;
}
.smsCheckArea .smsRecieveText {
  position: relative;
}
.smsCheckArea .smsRecieveText:before {
  content: "\f0c8";
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
  font: normal normal 100 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.smsCheckArea .smsRecieveText.smsRecieveTextActive:before {
  content: "\f14a";
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
  font: normal normal 900 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #3b99fc;
}
#bump-offer {
  cursor: normal !important;
}

.containerModal {
  position: fixed;
  overflow-y: auto;
}
.de2column { /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
}
.de3column { /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
}
.de4column { /* Chrome, Safari, Opera */
  -moz-column-count: 4; /* Firefox */
  column-count: 4;
}
.de5column { /* Chrome, Safari, Opera */
  -moz-column-count: 5; /* Firefox */
  column-count: 5;
}

/* start with member content example hidden on live pages */
.video-description {
  display: none;
}
.innerContent {
  word-wrap: break-word;
}
.editor-video-holder {
  display: none !important;
}
@media screen and (max-width: 500px) {
  #tubular-container {
    display: none !important;
  }
}

/* oto loading screen */
.otoloading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.otoloadingtext {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 4px;
  padding: 60px;
  margin-top: 20px;
  margin-left: -100px;
  min-width: 200px;
  position: absolute;
  left: 50%;
  font-weight: bold;
}
.otoloadingtext h2 {
  font-weight: bold;
}
.otoloadingtext i {
  font-size: 72px;
}

/* order summary alignments */
.elOrderProductOptinProductName {
  width: 100% !important;
}
.elOrderProductOptinPrice {
  width: 30% !important;
  text-align: right;
}
.elOrderProductOptinProductName.product-name,
.elOrderProductOptinTableProductName {
  width: 70% !important;
}

.elOrderProductOptinPrice.product-price,
.elOrderProductOptinTablePriceName {
  width: 30% !important;
  text-align: right;
}

/* order bump add product fix */
#product-bump-name {
  width: 70% !important;
  padding-top: 10px;
}
#product-bump-price {
  padding-top: 10px;
}
.gr-textarea-btn {
  display: none !important;
}
.ea-buttonWobble {
  transition: 0.3s;
}
.ea-buttonWobble:hover {
  animation: wobble 0.5s 1;
  animation-timing-function: ease-in-out;
}
@keyframes wobble {
  0% {
    transform: skewX(0deg);
  }
  25% {
    transform: skewX(10deg);
  }
  50% {
    transform: skewX(0deg);
  }
  75% {
    transform: skewX(-10deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
.ea-buttonStretch {
  padding: 8px 50px;
  border-radius: 3px;
  transition: 0.2s;
}
.ea-buttonStretch:hover {
  padding: 10px 100px;
}
.ea-buttonPulseGlow {
  animation: pulseGlow 2s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes pulseGlow {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0px 0px 2.5px 1px rgba(255, 255, 255, 0.25);
  }
  50% {
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.5);
  }
  85% {
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
  }
}

.ea-buttonRocking {
  animation: rocking 2s infinite;
  animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  transition: 0.2s;
}
@keyframes rocking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ea-buttonRocking:hover {
  animation: none !important;
}

.ea-buttonBounce {
  animation: bounce 1.5s infinite;
  animation-timing-function: ease-in;
  transition: 0.2s;
}
.ea-buttonBounce:hover {
  animation: none !important;
}
@keyframes bounce {
  15% {
    transform: translateY(0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  35% {
    transform: translateY(-35%);
    box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.25);
  }
  45% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  55% {
    transform: translateY(-20%);
    box-shadow: 0px 5px 4px -4px rgba(0, 0, 0, 0.25);
  }
  70% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  80% {
    transform: translateY(-10%);
    box-shadow: 0px 4px 3px -3px rgba(0, 0, 0, 0.25);
  }
  90% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  95% {
    transform: translateY(-2%);
    box-shadow: 0px 2px 3px -3px rgba(0, 0, 0, 0.25);
  }
  99% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateY(0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

.video-description .midContainer {
  max-width: 100% !important;
}

.email_suggestion {
  margin: 10px;
  display: block;
  text-align: left;
  color: grey;
}

.sf_answer_h_blue .surveyRadioOption:hover,
.sf_answer_h_blue .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #0e8dc7;
}
.sf_answer_h_blue .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_blue
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_blue
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_blue .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_blue
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_blue
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}

.sf_answer_h_green .surveyRadioOption:hover,
.sf_answer_h_green .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #96bd2c;
}
.sf_answer_h_green .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_green
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_green
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_green .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_green
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_green
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}

.sf_answer_h_yellow .surveyRadioOption:hover,
.sf_answer_h_yellow .surveyStepSelectedAnswer {
  color: rgba(66, 21, 21, 0.7);
  background-color: #ffcc33;
}
.sf_answer_h_yellow .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_yellow
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_yellow
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_yellow .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_yellow
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_yellow
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: rgba(66, 21, 21, 0.7) !important;
}

.sf_answer_h_red .surveyRadioOption:hover,
.sf_answer_h_red .surveyStepSelectedAnswer {
  color: #ffffff;
  background-color: rgb(206, 53, 26);
}
.sf_answer_h_red .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_red
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_red
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_red .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_red
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_red
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #ffffff !important;
}

.sf_answer_h_orange .surveyRadioOption:hover,
.sf_answer_h_orange .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #fba30a;
}
.sf_answer_h_orange .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_orange
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_orange
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_orange .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_orange
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_orange
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}

.sf_answer_h_black .surveyRadioOption:hover,
.sf_answer_h_black .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #3d3f47;
}
.sf_answer_h_black .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_black
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_black
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_black .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_black
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_black
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}

.sf_answer_hs_growshadow .surveyRadioOption:hover,
.sf_answer_hs_growshadow .surveyStepSelectedAnswer {
  transform: scale(1.03);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14),
    inset 0 0 0 2px rgba(255, 255, 255, 0.16) !important;
}

.o2step_step2 .paypal-button {
  margin-top: 20px;
}

#order-declined-message ul {
  list-style: inside;
  margin-bottom: 0px;
}

.socialLikesPreview {
  display: none !important;
}
.socialLikesLive {
  display: block !important;
}

/* Document broad styles covering some defaults from bootstrap initially has */
body {
  margin: 0px;
}

html,
body,
.containerWrapper,
.elPageContent,
.elPageContentWrapper {
  height: 100%;
  min-height: 100%;
}

/*
html {
  all: initial;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
} */

/* h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
  line-height: 1.1;
} */

.cf-submitting-page {
  cursor: wait;
  pointer-events: none;
}

.popup-relative-position {
  position: relative;
}
.remove-page-scroll {
  overflow-y: hidden !important;
}
.hide-page-scroll::-webkit-scrollbar {
  display: none;
}

.hide-page-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mailcheck {
  margin: 0;
  text-align: left;
  position: relative;
}

.elCustomHtmlJsWidget {
  display: none;
}

input:-webkit-autofill, select:-webkit-autofill { 
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.elVideoWrapper[data-video-aspect-ratio="vertical"] .fluid-width-video-wrapper {
  padding-top: 0 !important;
}

.elVideoWrapper[data-video-aspect-ratio="vertical"] iframe {
  position: relative !important;
  height: auto !important;
  aspect-ratio: 9 / 16 !important;
}

.elVideoWrapper[data-video-aspect-ratio="vertical"] .fluid-width-video-wrapper iframe {
  width: 100% !important;
}