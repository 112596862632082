.mejs__overlay-button {
  background: url(https://images.clickfunnels.com/images/mejs-controls.svg) no-repeat;
}

.mejs__overlay-loading-bg-img {
  background: transparent url(https://images.clickfunnels.com/images/mejs-controls.svg) -160px -40px no-repeat;
}

.mejs__controls{
  display: flex;
  flex-wrap: wrap;
}
